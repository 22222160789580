/**
 * Interface with iOS AEA application
 */
import * as I18n from "i18n-js";
import { BiometricApi, BiometricAuthResult } from "misc/biometric_api";

interface Webkit {
  readonly messageHandlers?: {
    readonly aea_auth?: AeaAuth;
  };
}

interface AeaResponseMessage {
  readonly error?: {
    readonly title: string;
  };
  readonly payload?: {
    readonly data: string;
    readonly result: boolean;
  };
}

declare global {
  interface Window {
    readonly webkit?: Webkit;
    biometricsAvailableCallback?: (data: AeaResponseMessage) => void;
    biometricsAuthenticationCallback?: (data: AeaResponseMessage) => void;
  }
}

export interface AeaMessage {
  readonly version: string;
  readonly payload: {
    readonly request: {
      readonly endpoint: "aea_ios_hasAuthenticationMethod" | "aea_ios_authenticateUser";
      readonly kwargs: any;
    };
    readonly callback: {
      readonly object: any;
      readonly function: string;
    };
  };
}

interface AeaAuth {
  postMessage(message: AeaMessage): void;
}

function getAeaAuth(): AeaAuth | undefined {
  return window.webkit?.messageHandlers?.aea_auth;
}

let biometricsAvailable = false;

class AeaApp implements BiometricApi {
  private readonly aeaAuth: AeaAuth | undefined;

  constructor() {
    this.aeaAuth = getAeaAuth();
  }

  /**
   * Checks if the AEA application is available.
   */
  isApiAvailable() {
    return !!this.aeaAuth;
  }

  /**
   * Checks if iOS biometric authentication is available.
   * Returns a promise that resolves to true if biometric authentication is available, false otherwise.
   */
  isBiometricAvailable(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.aeaAuth) {
        resolve(false);
        return;
      }
      window.biometricsAvailableCallback = function (data) {
        if (data.error) {
          reject(data.error.title);
          return;
        }
        if (data.payload && data.payload.result) {
          biometricsAvailable = true;
          resolve(true);
          return;
        } else {
          // biometrics not available
          resolve(false);
          return;
        }
      };

      const message: AeaMessage = {
        version: "0.1.0",
        payload: {
          request: {
            endpoint: "aea_ios_hasAuthenticationMethod",
            kwargs: {
              auth_method: "com.amazon.aea.ios.auth_method.Biometric",
              auth_type: "JWT",
            },
          },
          callback: {
            object: null,
            function: "biometricsAvailableCallback",
          },
        },
      };
      this.aeaAuth.postMessage(message);
    });
  }

  /**
   * Prompts user to perform biometric authentication.
   * Returns a promise that resolves to a list of JWT claims which indicate the user is authenticated.
   * @returns {Promise<BiometricAuthResult>}
   */
  performBiometricAuthentication(): Promise<BiometricAuthResult> {
    return new Promise<BiometricAuthResult>((resolve, reject) => {
      if (!this.aeaAuth) {
        reject(I18n.t("js.touch_id_is_not_available_on_this_device"));
        return;
      }
      window.biometricsAuthenticationCallback = function (data) {
        if (data.error || !data.payload || !data.payload.data) {
          reject(data.error?.title || I18n.t("js.touchid_authentication_failed"));
          return;
        }
        const claim = data.payload.data;
        resolve({ claim: claim });
      };

      const message: AeaMessage = {
        version: "0.1.0",
        payload: {
          request: {
            endpoint: "aea_ios_authenticateUser",
            kwargs: {
              auth_method: "com.amazon.aea.ios.auth_method.Biometric",
              auth_type: "JWT",
            },
          },
          callback: {
            object: null,
            function: "biometricsAuthenticationCallback",
          },
        },
      };
      this.aeaAuth.postMessage(message);
    });
  }
}

export const iOSAeaApp = new AeaApp();
